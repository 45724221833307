import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.hash) {
    return location.hash;
  }
  const currentPosition = getSavedScrollPosition(location);
  setTimeout(() => window.scrollTo(...(currentPosition || [0, 0])), 1);
  return false;
};

// ======================================================================
// Overriding the setting set by gatsby-plugin-styled-components to be able to
// handle the non transient props.
// Code from: https://github.com/gatsbyjs/gatsby/blob/4ef1d474eb42f378f120ba2e702533dc68de7222/packages/gatsby-plugin-styled-components/src/gatsby-ssr.js
// Migration: https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
// ======================================================================

export const wrapRootElement = ({ element }) => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    {element}
  </StyleSheetManager>
);

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  return typeof target === 'string' ? isPropValid(propName) : true;
}
